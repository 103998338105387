<template>
    <v-dialog v-model="show" persistent>
        <v-alert
            v-model="show_alert"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
            dismissible
        >
            {{ alert_message }}
        </v-alert>
        <v-card v-for="(item, i) in items" :key="i" v-show="i + 1 == current">
            <v-btn
                icon
                color="#4c9aff"
                class="place-top-right"
                @click="show = false"
            >
                <v-icon>fa-times</v-icon>
            </v-btn>
            <ScannableInput
                :active="isScannableInputEnableForBundleItems && show"
                :debug="false"
                @scan="scan"
            ></ScannableInput>
            <v-card-text class="pt-5">
                <h2 class="title text-center mb-3">
                    {{ item.orderItem.name }}
                </h2>
                <v-img
                    class="ma-auto mt-2 mb-4 item-image"
                    :style="{
                        backgroundImage: item.image
                            ? 'url(' + item.image + ')'
                            : 'url(' + placeholderPath + ')'
                    }"
                    max-width="200"
                    max-height="200"
                    contain
                />
                <v-simple-table class="text-left fullwidth" dense s>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <th>SKU</th>
                                <td>
                                    {{ item.orderItem.sku }}
                                </td>
                            </tr>
                            <tr>
                                <th>UPC</th>
                                <td>
                                    {{ item.barcode }}
                                </td>
                            </tr>
                            <tr v-if="item.location">
                                <th>Location</th>
                                <td>{{ item.location }}</td>
                            </tr>
                            <tr v-show="parentItemName != ''">
                                <th>Part of bundle</th>
                                <td>
                                    {{ parentItemName }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <PullItemActions
                    @manualConfirmOrderItem="manualConfirmOrderItem"
                    @setRestockNeeded="setRestockNeeded"
                    @setOutOfStock="setOutOfStock"
                    @markItemAsSubstitute="markItemAsSubstitute"
                ></PullItemActions>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.place-top-right {
    position: absolute;
    top: 0;
    right: 0;
}
.item-image {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
}
</style>
<script>
import { mapState } from 'vuex';
import ScannableInput from '@/components/ScannableInput';
import PullItemActions from '@/components/PullItemActions';
import * as data from '@/data/index';

export default {
    props: {
        orderId: String,
        items: {
            type: Array,
            default() {
                return [];
            }
        },
        parentItemName: {
            type: String,
            default: ''
        },
        parentItemId: {
            type: String,
            default: ''
        },
        currentUserName: {
            type: String,
            default: ''
        },
        orderCartId: {
            type: String,
            default: ''
        },
        value: Boolean
    },
    data() {
        return {
            current: 1,
            isScannableInputEnableForBundleItems: false,
            currentIncompleteBundleItem: {},
            show_alert: false,
            alert_message: '',
            placeholderPath: require('@/assets/placeholder.png'),
            hasOutOfStockItem: false,
            hasRestockItem: false
        };
    },
    components: {
        ScannableInput,
        PullItemActions
    },
    computed: {
        ...mapState(['currentOrder']),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        value(newValue) {
            if (newValue == true) {
                this.preLoadRoutine();
                this.isScannableInputEnableForBundleItems = true;
            } else {
                this.isScannableInputEnableForBundleItems = false;
            }
        }
    },
    methods: {
        preLoadRoutine() {
            if (this.items.length) {
                this.items.every((item, index) => {
                    if (item.status == 'incomplete') {
                        this.currentIncompleteBundleItem = item;
                        this.current = index + 1;
                        return false;
                    }
                    return true;
                });
                if (
                    Object.keys(this.currentIncompleteBundleItem).length === 0
                ) {
                    this.markParentItemAsComplete();
                }
            }
        },

        async scan(response) {
            this.alert_message = '';
            this.show_alert = false;
            if (response.input != this.currentIncompleteBundleItem.barcode) {
                this.alert_message = 'You are scanning the incorrect item.';
                this.show_alert = true;
                return;
            }
            let message = '';
            if (this.currentIncompleteBundleItem.orderItem.quantity > 1) {
                message =
                    'There should be ' +
                    this.currentIncompleteBundleItem.orderItem.quantity +
                    ' of this item';
            }
            if (
                this.currentIncompleteBundleItem.orderItem.sku.includes('-case')
            ) {
                message =
                    'This item is a case (x' +
                    this.currentIncompleteBundleItem.orderItem.quantity +
                    ')';
            }

            if (
                message != '' &&
                (await this.$root.$confirmDialog.open(null, message, {}))
            ) {
                this.scanItemBarcode();
            } else {
                this.scanItemBarcode();
            }
        },

        scanItemBarcode() {
            this.completeOrderItem();
            this.moveToNextItem();
        },

        completeOrderItem() {
            this.$store.dispatch('updateVerificationItem', {
                orderId: this.orderId,
                itemId: this.currentIncompleteBundleItem.orderItem.id,
                data: {
                    status: 'complete',
                    confirm: {
                        method: 'scan'
                    }
                }
            });
        },

        moveToNextItem() {
            this.alert_message = '';
            this.show_alert = false;

            let nextBundleItem = {};
            this.items.every((item, index) => {
                if (index + 1 > this.current) {
                    if (item.status == 'incomplete') {
                        nextBundleItem = item;
                        this.current = index + 1;
                        return false;
                    }
                }
                return true;
            });
            if (nextBundleItem && nextBundleItem.status) {
                this.currentIncompleteBundleItem = nextBundleItem;
                return;
            }
            if (this.hasOutOfStockItem) {
                this.markParentItemAsOutOfStock();
                this.moveToNextMainItem();
                return;
            }
            if (this.hasRestockItem) {
                this.$emit('setOrderHasRestockItem');
                this.moveToNextMainItem();
                return;
            }
            this.markParentItemAsComplete();
        },

        async setOutOfStock() {
            if (
                await this.$root.$confirmDialog.open(null, 'Are you sure?', {})
            ) {
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteBundleItem.orderItem.id,
                    data: {
                        status: 'out-of-stock'
                    }
                });
                data.orderHistory.save({
                    orderId: this.orderId,
                    action: 'out-of-stock',
                    description:
                        'Bundle item ' +
                        this.currentIncompleteBundleItem.orderItem.sku +
                        ' is marked as Out-of-Stock by ' +
                        this.currentUserName
                });
                this.hasOutOfStockItem = true;
                this.moveToNextItem();
            }
        },

        markItemAsSubstitute(response) {
            this.$store.dispatch('updateVerificationItem', {
                orderId: this.orderId,
                itemId: this.currentIncompleteBundleItem.orderItem.id,
                data: {
                    status: 'complete',
                    confirm: {
                        method: 'replacement',
                        additional: response.sku + ': ' + response.upc
                    }
                }
            });
            this.moveToNextItem();
        },

        async setRestockNeeded() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'The item needs to be restocked?',
                    {}
                )
            ) {
                this.$store.dispatch('createRestockItem', {
                    sku: this.currentIncompleteBundleItem.orderItem.sku,
                    qtyNeeded: this.currentIncompleteBundleItem.orderItem
                        .quantity,
                    cartId: this.orderCartId,
                    requested: Date.now()
                });
                this.$store.dispatch('updateOrder', {
                    orderId: this.$route.params.id,
                    data: {
                        internalStatus: 'needs-restock'
                    }
                });
                data.orderHistory.save({
                    orderId: this.orderId,
                    action: 'restock-needed',
                    description:
                        'Bundle item ' +
                        this.currentIncompleteBundleItem.orderItem.sku +
                        ' is marked as Awaiting Restock by ' +
                        this.currentUserName
                });
                this.hasRestockItem = true;
                this.moveToNextItem();
            }
        },

        async manualConfirmOrderItem() {
            let message = 'This will manually confirm the item';
            if (
                this.currentIncompleteBundleItem.orderItem.sku.includes('-case')
            ) {
                message =
                    message +
                    ' (' +
                    this.currentIncompleteBundleItem.orderItem.quantity +
                    ')';
            }
            if (await this.$root.$confirmDialog.open(null, message, {})) {
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteBundleItem.orderItem.id,
                    data: {
                        status: 'complete',
                        confirm: {
                            method: 'manual'
                        }
                    }
                });
                this.moveToNextItem();
            }
        },

        markParentItemAsOutOfStock() {
            this.$store.dispatch('updateVerificationItem', {
                orderId: this.orderId,
                itemId: this.parentItemId,
                data: {
                    status: 'out-of-stock'
                }
            });
        },

        markParentItemAsComplete() {
            this.$emit('completeCurrentBundleProduct');
            this.show = false;
        },

        moveToNextMainItem() {
            this.$emit('moveToNextMainItem');
            this.show = false;
        }
    }
};
</script>
