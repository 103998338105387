<template>
    <div class="fixed-top-right" v-show="debug && queue.length > 0">
        <v-badge
            v-for="(code, index) in queue"
            :key="index"
            color="primary"
            :content="code.charcode"
        >
            {{ code.key }}
        </v-badge>
    </div>
</template>

<style scoped>
.fixed-top-right {
    position: fixed;
    top: 100px;
    right: 100px;
    border: 1px solid #000;
    padding: 20px 10px 10px;
    background: #fff;
}
.fixed-top-right > span {
    margin-right: 30px;
}
</style>

<script>
export default {
    name: 'ScannableInput',
    props: {
        active: {
            type: Boolean,
            default: false
        },
        debug: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            queue: [],
            terminating_keys: ['Enter', 'Tab'],
            dash_characters: [173, 189, 109],
            scanning_bound_min: 45,
            scanning_bound_max: 90
        };
    },
    created() {
        if (this.active == true) {
            window.addEventListener('keydown', this.inputListenEventHandler);
        }
    },
    watch: {
        active: {
            handler(newValue) {
                if (newValue == true) {
                    this.active = true;
                    window.addEventListener(
                        'keydown',
                        this.inputListenEventHandler
                    );
                }
                if (newValue == false) {
                    this.active = false;
                    window.removeEventListener(
                        'keydown',
                        this.inputListenEventHandler
                    );
                }
            },
            deep: true
        }
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.inputListenEventHandler);
    },
    methods: {
        inputListenEventHandler(e) {
            if (this.terminating_keys.includes(e.key)) {
                this.scan();
            } else {
                let inputCharCode = e.key.charCodeAt(0);
                let key = String.fromCharCode(inputCharCode);

                if (inputCharCode < this.scanning_bound_min) {
                    return;
                }
                if (
                    inputCharCode > this.scanning_bound_max &&
                    !this.dash_characters.includes(inputCharCode)
                ) {
                    return;
                }
                if (inputCharCode > this.scanning_bound_max) {
                    key = '-';
                    inputCharCode = 45;
                }
                this.queue.push({ key: key, charcode: inputCharCode });
            }
        },

        scan() {
            const input = [];
            this.queue.forEach(item => {
                input.push(item.key);
            });
            this.queue = [];
            this.$emit('scan', { input: input.join('') });
        }
    }
};
</script>
