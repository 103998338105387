<template>
    <v-row no-gutters align="center">
        <v-col>
            <v-card elevation="0">
                <v-alert
                    v-model="show_alert"
                    color="#4c9aff"
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                    dismissible
                >
                    {{ alert_message }}
                </v-alert>
                <ScannableInput
                    :active="
                        isScannableInputEnableForMainItems &&
                            canShowBundleItems == false
                    "
                    :debug="false"
                    @scan="scan"
                />
                <v-card
                    elevation="0"
                    class="text-center pb-3 overflow-auto"
                    v-if="canShowItems"
                >
                    <h2 class="title float-left">Order ID: {{ orderId }}</h2>
                    <h3 class="subtitle float-right">
                        <v-icon
                            class="align-baseline"
                            color="secondary lighten-1"
                            >fa-barcode</v-icon
                        >
                        {{
                            this.currentOrder &&
                            this.currentOrder.verification &&
                            this.currentOrder.verification.cartId != ''
                                ? this.currentOrder.verification.cartId
                                : 'Something went wrong.'
                        }}
                    </h3>
                </v-card>
                <v-card
                    elevation="0"
                    class="text-center pb-3 overflow-auto"
                    v-else
                >
                    <h2 class="title">Order ID: {{ orderId }}</h2>
                    <h3 class="subtitle">
                        <v-icon
                            class="align-baseline"
                            color="secondary lighten-1"
                            >fa-barcode</v-icon
                        >
                        {{
                            this.currentOrder &&
                            this.currentOrder.verification &&
                            this.currentOrder.verification.cartId != ''
                                ? this.currentOrder.verification.cartId
                                : 'Scan a Cart to begin this Order.'
                        }}
                    </h3>
                </v-card>

                <div v-show="canShowItems">
                    <v-card
                        v-for="(item, idx) in visibleOrderItems"
                        :key="idx"
                        elevation="3"
                        v-show="idx + 1 == current"
                    >
                        <v-card-text class="text-center">
                            <h3>
                                {{ item.orderItem.name }}
                            </h3>
                            <v-img
                                class="ma-auto mt-2 mb-4 item-image"
                                :style="{
                                    backgroundImage: item.image
                                        ? 'url(' + item.image + ')'
                                        : 'url(' + placeholderPath + ')'
                                }"
                                max-width="200"
                                max-height="200"
                                contain
                            />
                            <v-row>
                                <v-simple-table
                                    class="text-left fullwidth"
                                    dense
                                    s
                                    v-if="currentOrder && currentOrder.order"
                                >
                                    <template v-slot:default>
                                        <tbody>
                                            <tr
                                                v-if="
                                                    currentOrder.is_perishable &&
                                                        currentOrder.is_perishable ===
                                                            true
                                                "
                                            >
                                                <td>Perishable</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr
                                                v-if="item.orderItem.quantity"
                                                :class="{
                                                    highlight: item.orderItem.sku.includes(
                                                        '-case'
                                                    )
                                                }"
                                            >
                                                <th>Qty</th>
                                                <td>
                                                    {{
                                                        item.orderItem.quantity
                                                    }}
                                                    <span
                                                        v-if="
                                                            item.orderItem.sku.includes(
                                                                '-case'
                                                            )
                                                        "
                                                    >
                                                        Case</span
                                                    >
                                                </td>
                                            </tr>
                                            <tr v-if="item.orderItem.sku">
                                                <th>SKU</th>
                                                <td>
                                                    {{ item.orderItem.sku }}
                                                </td>
                                            </tr>
                                            <tr v-if="item.location">
                                                <th>Location</th>
                                                <td>{{ item.location }}</td>
                                            </tr>
                                            <tr v-if="item.barcode">
                                                <th>UPC</th>
                                                <td>
                                                    {{ item.barcode }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-row>
                            <div v-if="isItemFromExternalOrder">
                                <v-row
                                    class="py-2"
                                    v-show="item.orderItem.product_id == 0"
                                    ><v-col
                                        ><h2>
                                            This product is from an external
                                            order, so it may not be scannable.
                                        </h2></v-col
                                    ></v-row
                                >
                                <v-row>
                                    <v-col
                                        ><div class="my-2">
                                            <v-btn
                                                block
                                                color="#4c9aff"
                                                dark
                                                elevation="0"
                                                @click="completeWithoutScan"
                                            >
                                                Complete Without Scan
                                            </v-btn>
                                        </div></v-col
                                    >
                                </v-row>
                            </div>
                            <div v-else>
                                <v-row
                                    v-show="
                                        bundleProductIds &&
                                            bundleProductIds.includes(
                                                item.orderItem.id
                                            )
                                    "
                                >
                                    <v-col>
                                        <div class="my-2">
                                            <v-btn
                                                block
                                                color="#4c9aff"
                                                dark
                                                elevation="0"
                                                @click="showBundleItems"
                                            >
                                                Scan Bundle Pieces
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                                <PullItemActions
                                    @manualConfirmOrderItem="
                                        manualConfirmOrderItem
                                    "
                                    @setRestockNeeded="setRestockNeeded"
                                    @setOutOfStock="setOutOfStock"
                                    @markItemAsSubstitute="markItemAsSubstitute"
                                ></PullItemActions>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card>
        </v-col>
        <BundleItems
            :items="bundleItems"
            :parentItemName="
                currentIncompleteItem.orderItem
                    ? currentIncompleteItem.orderItem.name
                    : ''
            "
            :parentItemId="
                currentIncompleteItem.orderItem
                    ? currentIncompleteItem.orderItem.id.toString()
                    : ''
            "
            :orderId="orderId.toString()"
            :currentUserName="this.currentUser.displayName"
            :orderCartId="
                this.currentOrder && this.currentOrder.verification
                    ? this.currentOrder.verification.cartId
                    : ''
            "
            v-model="canShowBundleItems"
            @completeCurrentBundleProduct="completeCurrentBundleProduct"
            @moveToNextMainItem="moveToNextItem"
            @setOrderHasRestockItem="setOrderHasRestockItem"
        ></BundleItems>
    </v-row>
</template>

<style scoped>
.item-image {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
}

.highlight {
    background-color: #fff300;
    padding: 0px 5px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import * as data from '@/data/index';
import ScannableInput from '@/components/ScannableInput';
import BundleItems from '@/components/BundleItems';
import PullItemActions from '@/components/PullItemActions';

export default {
    name: 'Pull',
    data: () => {
        return {
            placeholderPath: require('@/assets/placeholder.png'),
            current: 1,
            isScannableInputEnableForMainItems: false,
            currentIncompleteItem: {},
            show_alert: false,
            alert_message: '',
            orderItems: [],
            visibleOrderItems: [],
            bundleItems: [],
            canShowBundleItems: false,
            bundleProductIds: [],
            hasRestockItem: false,
            isNewCustomerOrder: false
        };
    },
    components: {
        ScannableInput,
        BundleItems,
        PullItemActions
    },
    computed: {
        ...mapState(['currentUser', 'currentOrder', 'currentOrderQueue']),
        ...mapGetters(['getCurrentUser']),
        orderId() {
            return this.$route.params.id;
        },
        canShowItems() {
            if (
                this.visibleOrderItems &&
                this.visibleOrderItems.length > 0 &&
                this.currentOrder &&
                this.currentOrder.verification &&
                this.currentOrder.verification.cartId != ''
            ) {
                return true;
            }
            return false;
        },
        isItemFromExternalOrder() {
            return (
                this.currentIncompleteItem &&
                this.currentIncompleteItem.orderItem &&
                this.currentIncompleteItem.orderItem.product_id == 0
            );
        }
    },
    beforeCreate() {
        let self = this;
        self.$root.$dialogLoader.show('Creating Verification Check…', {});
        data.orders
            .getOrder(self.$route.params.id)
            .then(async querySnapshot => {
                let order = querySnapshot.data();
                order.verification = await data.orders.getVerificationForOrder(
                    order,
                    self.currentUser
                );
                self.$store.dispatch('assignCurrentOrder', order.order.id).then(
                    () => {
                        this.orderItems = order.verification.items;
                        this.visibleOrderItems = self.getVisibleOrderItems();
                        if (
                            order.assigned != null &&
                            order.assigned != self.currentUser.uid
                        ) {
                            return self.redirectToOrderViewPagePromptReassign();
                        }
                        if (
                            order.verification != null &&
                            order.verification.status == 'complete'
                        ) {
                            self.redirectToHomePage(
                                'The order has already been completed.'
                            );
                        } else if (
                            order.verification != null &&
                            order.verification.status == 'merged'
                        ) {
                            self.redirectToHomePage(
                                'The order has been merged to order #' +
                                    order.merged_into_order
                            );
                        } else {
                            let wasOrderAssigned = true;
                            if (order.assigned === null) {
                                self.$store.dispatch('updateOrder', {
                                    orderId: self.$route.params.id,
                                    data: { assigned: self.currentUser.uid }
                                });
                                wasOrderAssigned = false;
                            }
                            if (order.internalStatus === 'queued') {
                                self.$store.dispatch('updateOrder', {
                                    orderId: self.$route.params.id,
                                    data: { internalStatus: 'in-progress' }
                                });
                                if (wasOrderAssigned === false) {
                                    data.orderHistory
                                        .save({
                                            orderId: self.$route.params.id,
                                            action: 'assigned',
                                            description:
                                                'Order assigned to ' +
                                                self.currentUser.displayName
                                        })
                                        .then(() => {
                                            data.orderHistory.save({
                                                orderId: self.$route.params.id,
                                                action: 'pulling-start',
                                                description:
                                                    'Order pulling is started by ' +
                                                    self.currentUser.displayName
                                            });
                                        });
                                } else {
                                    data.orderHistory.save({
                                        orderId: self.$route.params.id,
                                        action: 'pulling-start',
                                        description:
                                            'Order pulling is started by ' +
                                            self.currentUser.displayName
                                    });
                                }
                            }
                            if (order.internalStatus === 'needs-restock') {
                                self.$store.dispatch('updateOrder', {
                                    orderId: self.$route.params.id,
                                    data: { internalStatus: 'in-progress' }
                                });
                                data.orderHistory.save({
                                    orderId: self.$route.params.id,
                                    action: 'pulling-resume',
                                    description:
                                        'Order pulling is resumed after a restock by ' +
                                        self.currentUser.displayName
                                });
                            }
                            if (order.internalStatus === 'verified') {
                                data.orderHistory.save({
                                    orderId: self.$route.params.id,
                                    action: 'packing-start',
                                    description:
                                        'Order starting to be packed by ' +
                                        self.currentUser.displayName
                                });
                            }
                            this.visibleOrderItems.every((item, index) => {
                                if (item.status == 'incomplete') {
                                    this.currentIncompleteItem = item;
                                    this.current = index + 1;
                                    return false;
                                }
                                return true;
                            });
                            if (
                                Object.keys(this.currentIncompleteItem)
                                    .length === 0
                            ) {
                                self.$router.push({ name: 'Complete' });
                                return;
                            }
                        }
                        this.identifyBundleProductIds();
                        self.isScannableInputEnableForMainItems = true;
                        self.showNewCustomerOrderFlag(order);
                        self.$root.$dialogLoader.hide();
                    },
                    error => {
                        console.log(error);
                    }
                );
            })
            .catch(function(error) {
                console.log('order not found: ', error);
                self.$root.$dialogLoader.hide();
                self.redirectToHomePage(
                    'There was a problem getting order information'
                );
            });
    },
    created() {},
    methods: {
        redirectToOrderViewPagePromptReassign() {
            const currentQueue = this.currentOrderQueue;
            this.$router.push({
                name: 'Order',
                params: {
                    order_type: currentQueue,
                    show_next_order_button: true,
                    prompt_reassign: true
                }
            });
        },
        redirectToOrderViewPage(alertMessage) {
            const currentQueue = this.currentOrderQueue;
            this.$router.push({
                name: 'Order',
                params: {
                    order_type: currentQueue,
                    show_next_order_button: true,
                    show_alert: true,
                    alert_message: alertMessage
                }
            });
        },
        redirectToHomePage(alertMessage) {
            this.$router.push({
                name: 'Home',
                params: {
                    show_alert: true,
                    alert_message: alertMessage
                }
            });
        },

        async scan(response) {
            this.alert_message = '';
            this.show_alert = false;
            if (this.currentOrder.verification.cartId == '') {
                data.verifications
                    .getByCart(response.input)
                    .then(async querySnapshot => {
                        if (querySnapshot.size) {
                            this.alert_message =
                                'This cart-id has been taken by another order.';
                            this.show_alert = true;
                        } else {
                            if (await this.checkIfOrderHasCartId()) {
                                return this.redirectToHomePage(
                                    'The order has been assigned to someone else.'
                                );
                            }
                            const patternToMatchNumberOnly = /^\d+$/;
                            if (
                                patternToMatchNumberOnly.test(
                                    response.input
                                ) === false
                            ) {
                                this.alert_message =
                                    'Only numeric value is allowed for the cart-id.';
                                this.show_alert = true;
                                return;
                            }
                            this.$store
                                .dispatch('updateVerification', {
                                    orderId: this.orderId,
                                    data: { cartId: response.input }
                                })
                                .then(() => {
                                    this.showNewCustomerOrderFlag(
                                        this.currentOrder
                                    );
                                });
                        }
                    });
            } else {
                this.alert_message = '';
                this.show_alert = false;
                if (response.input != this.currentIncompleteItem.barcode) {
                    this.alert_message = 'You are scanning the incorrect item.';
                    this.show_alert = true;
                    return;
                }
                let message = '';
                if (this.currentIncompleteItem.orderItem.quantity > 1) {
                    message =
                        'There should be ' +
                        this.currentIncompleteItem.orderItem.quantity +
                        ' of this item';
                }
                if (
                    this.currentIncompleteItem.orderItem.sku.includes('-case')
                ) {
                    message =
                        'This item is a case (x' +
                        this.currentIncompleteItem.orderItem.quantity +
                        ')';
                }

                if (message === '') {
                    this.scanItemBarcode();
                    return;
                }
                if (await this.$root.$confirmDialog.open(null, message, {})) {
                    this.scanItemBarcode();
                }
            }
        },

        scanItemBarcode() {
            this.completeOrderItem();
            this.moveToNextItem();
        },

        completeOrderItem() {
            this.$store.dispatch('updateVerificationItem', {
                orderId: this.orderId,
                itemId: this.currentIncompleteItem.orderItem.id,
                data: {
                    status: 'complete',
                    confirm: {
                        method: 'scan'
                    }
                }
            });
        },

        moveToNextItem() {
            this.alert_message = '';
            this.show_alert = false;
            this.bundleItems = [];
            let nextItem = {};
            this.visibleOrderItems.every((item, index) => {
                if (index + 1 > this.current) {
                    if (item.status == 'incomplete') {
                        nextItem = item;
                        this.current = index + 1;
                        return false;
                    }
                }
                return true;
            });
            if (nextItem && nextItem.status) {
                this.currentIncompleteItem = nextItem;
            } else {
                if (
                    this.currentOrder.internalStatus == 'needs-restock' ||
                    this.hasRestockItem
                ) {
                    this.$store.dispatch('updateOrder', {
                        orderId: this.orderId,
                        data: {
                            assigned: null
                        }
                    });
                    this.redirectToHomePage(
                        'The order was flagged for restocking'
                    );
                } else {
                    data.orderHistory.save({
                        orderId: this.$route.params.id,
                        action: 'pulling-end',
                        description:
                            'Order is finished picking by ' +
                            this.currentUser.displayName
                    });
                    this.$router.push({ name: 'Complete' });
                }
            }
        },

        async setOutOfStock() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'This item is out of stock?',
                    {}
                )
            ) {
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteItem.orderItem.id,
                    data: {
                        status: 'out-of-stock'
                    }
                });
                await this.updateAllBundleItemsAtOnce({
                    status: 'out-of-stock'
                });
                data.orderHistory.save({
                    orderId: this.$route.params.id,
                    action: 'out-of-stock',
                    description:
                        'Item ' +
                        this.currentIncompleteItem.orderItem.sku +
                        ' is marked as Out-of-Stock by ' +
                        this.currentUser.displayName
                });
                this.moveToNextItem();
            }
        },

        async markItemAsSubstitute(response) {
            this.$store.dispatch('updateVerificationItem', {
                orderId: this.orderId,
                itemId: this.currentIncompleteItem.orderItem.id,
                data: {
                    status: 'complete',
                    confirm: {
                        method: 'replacement',
                        additional: response.sku + ': ' + response.upc
                    }
                }
            });
            await this.updateAllBundleItemsAtOnce({
                status: 'complete',
                confirm: {
                    method: 'replacement'
                }
            });
            this.moveToNextItem();
        },

        async setRestockNeeded() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'The item needs to be restocked?',
                    {}
                )
            ) {
                this.$store.dispatch('createRestockItem', {
                    sku: this.currentIncompleteItem.orderItem.sku,
                    qtyNeeded: this.currentIncompleteItem.orderItem.quantity,
                    cartId: this.currentOrder.verification.cartId,
                    requested: Date.now()
                });
                this.$store.dispatch('updateOrder', {
                    orderId: this.$route.params.id,
                    data: {
                        internalStatus: 'needs-restock'
                    }
                });
                data.orderHistory.save({
                    orderId: this.$route.params.id,
                    action: 'restock-needed',
                    description:
                        'Item ' +
                        this.currentIncompleteItem.orderItem.sku +
                        ' is marked as Awaiting Restock by ' +
                        this.currentUser.displayName
                });
                this.hasRestockItem = true;
                this.moveToNextItem();
            }
        },

        async manualConfirmOrderItem() {
            let message = 'This will manually confirm the item';
            if (this.currentIncompleteItem.orderItem.sku.includes('-case')) {
                message =
                    message +
                    ' (' +
                    this.currentIncompleteItem.orderItem.quantity +
                    ')';
            }
            if (await this.$root.$confirmDialog.open(null, message, {})) {
                const payload = {
                    status: 'complete',
                    confirm: {
                        method: 'manual'
                    }
                };
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteItem.orderItem.id,
                    data: payload
                });
                await this.updateAllBundleItemsAtOnce(payload);
                this.moveToNextItem();
            }
        },

        async showBundleItems() {
            let self = this;
            self.$root.$dialogLoader.show('Loading Bundle Items…', {});
            await this.loadBundleItems(true);
            if (this.bundleItems && this.bundleItems.length) {
                this.canShowBundleItems = true;
            } else {
                self.alert_message = 'No bundle items found for this item.';
                self.show_alert = true;
            }
            self.$root.$dialogLoader.hide();
        },

        async loadBundleItems(forceLoad = false) {
            let self = this;
            if (
                forceLoad === false &&
                this.bundleItems &&
                this.bundleItems.length
            ) {
                return; // already loaded
            }
            await data.products
                .getBundleItems(self.currentIncompleteItem.orderItem.id)
                .then(querySnapshot => {
                    let bundleItems = querySnapshot.docs.map(doc => doc.data());
                    bundleItems.sort((a, b) => {
                        return a.locationNumber - b.locationNumber;
                    });
                    this.bundleItems = bundleItems;
                })
                .catch(function(error) {
                    console.error(error);
                    self.alert_message = 'Error while finding bundle items.';
                    self.show_alert = true;
                    self.$root.$dialogLoader.hide();
                });
            return;
        },

        getVisibleOrderItems() {
            let self = this;
            let nonGiftBundleItemsIds = [];
            self.orderItems.forEach(item => {
                if (
                    Object.prototype.hasOwnProperty.call(
                        self.currentOrder.bundle_items,
                        item.orderItem.id
                    ) &&
                    !item.orderItem.sku.startsWith('G')
                ) {
                    nonGiftBundleItemsIds.push(item.orderItem.id);
                }
            });
            let itemsWithoutChildren = self.orderItems.filter(
                item =>
                    item.orderItem.is_bundled_product == false &&
                    !nonGiftBundleItemsIds.includes(item.orderItem.id)
            );
            let mainItems = itemsWithoutChildren.concat(
                self.getNonGiftBundleItemsChildren(nonGiftBundleItemsIds)
            ); // merging non-gift bundle items' children

            const itemsWithGiftBundle = mainItems.filter(
                item =>
                    Object.prototype.hasOwnProperty.call(
                        self.currentOrder.bundle_items,
                        item.orderItem.id
                    ) && item.orderItem.sku.startsWith('G')
            );
            itemsWithGiftBundle.forEach(function(element) {
                var index = mainItems.indexOf(element);
                mainItems.splice(index, 1);
            });
            mainItems.sort((a, b) => {
                return a.locationNumber - b.locationNumber;
            });
            return itemsWithGiftBundle.concat(mainItems);
        },

        getNonGiftBundleItemsChildren(nonGiftBundleItemsIds) {
            if (nonGiftBundleItemsIds.length < 1) {
                return [];
            }

            let self = this;
            let nonGiftBundleItemsChildren = [];
            nonGiftBundleItemsIds.every(nonGiftBundleItemId => {
                const children = self.orderItems.filter(
                    item =>
                        item.orderItem.parent_order_product_id ==
                        nonGiftBundleItemId
                );
                nonGiftBundleItemsChildren = nonGiftBundleItemsChildren.concat(
                    children
                );
                self.$store.dispatch('updateVerificationItem', {
                    orderId: self.orderId,
                    itemId: nonGiftBundleItemId,
                    data: {
                        status: 'complete',
                        confirm: {
                            method: 'scan'
                        }
                    }
                });
                return true;
            });
            return nonGiftBundleItemsChildren;
        },

        identifyBundleProductIds() {
            this.orderItems.every(item => {
                if (!this.checkIfItemIsBundleProduct(item)) {
                    return true;
                }
                if (!item.orderItem.sku.startsWith('G')) {
                    // item is a bundle product but it's a non-gift bundle product
                    // which doesn't need to be shown as bundle product as per SSD-3913.
                    return true;
                }
                item.orderItem.product_options.every(option => {
                    if (option.type === 'Product Pick List') {
                        this.bundleProductIds.push(item.orderItem.id);
                        return false;
                    }
                    return true;
                });
                return true;
            });
        },

        checkIfItemIsBundleProduct(item) {
            if (item.orderItem.is_bundled_product == true) {
                return false;
            }
            return !!(
                item.orderItem.product_options &&
                item.orderItem.product_options.length
            );
        },

        async completeWithoutScan() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'This will manually confirm the item',
                    {}
                )
            ) {
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteItem.orderItem.id,
                    data: {
                        status: 'complete',
                        confirm: {
                            method: 'manual (External Order)'
                        }
                    }
                });
                this.moveToNextItem();
            }
        },

        async updateAllBundleItemsAtOnce(payload) {
            await this.loadBundleItems();
            this.bundleItems.forEach(item => {
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: item.orderItem.id,
                    data: payload
                });
            });
        },

        completeCurrentBundleProduct() {
            this.completeOrderItem();
            this.moveToNextItem();
        },

        setOrderHasRestockItem() {
            this.hasRestockItem = true;
        },

        async checkIfOrderHasCartId() {
            return await data.orders
                .getOrderVerification(this.currentOrder.order.id)
                .then(snapshot => {
                    const document = snapshot.data();
                    return document.cartId != '';
                });
        },

        async showNewCustomerOrderFlag(order) {
            if (!this.canShowItems) {
                return;
            }

            const customerEmail = order.order?.customer_email;
            if (!customerEmail) {
                return;
            }
            this.$root.$dialogLoader.show(
                "Checking if it's customer first order…",
                {}
            );
            if (await this.doesOrderBelongToNewCustomer(customerEmail)) {
                this.$root.$confirmDialog.open(
                    null,
                    'This is the first order of the customer.',
                    {}
                );
            }
            this.$root.$dialogLoader.hide();
        },

        async doesOrderBelongToNewCustomer(customerEmail) {
            const orders = await data.orders.getCustomerOrders(customerEmail);
            if (
                orders &&
                orders.length == 1 &&
                orders[0].billing_address.email == customerEmail
            ) {
                return true;
            }
            return false;
        }
    }
};
</script>
